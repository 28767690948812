<template>
  <section class="c-join container d-flex">
    <div class="c-join__wrap relative">
      <img
        loading="lazy"
        :src="
          imgUrl(
            'https://api.dominter.net/media/uploads/blog/command.png',
            1020,
            0,
          )
        "
        alt="command"
        class="c-join__img"
      >
      <h2 class="c-join__title headline-2">
        {{ title }}
      </h2>
      <div
        class="c-join__text title-5"
        v-html="text"
      />
      <a
        href="/about/history/"
        class="c-join__link primary--text title-4"
        @click.prevent="$router.push({ path: '/about/history/' })"
      >Читать историю компании</a>
      <div class="c-join__socials">
        <button
          v-if="socials.vk"
          @click="goAway(`/away/?to=${socials.vk}`)"
        >
          <img
            loading="lazy"
            src="@/assets/images/useful/vk.svg"
            alt="VK"
          >
        </button>
        <button
          v-if="socials.yt"
          @click="goAway(`/away/?to=${socials.yt}`)"
        >
          <img
            loading="lazy"
            src="@/assets/images/useful/yt.svg"
            alt="YouTube"
          >
        </button>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { goAway } from '~/helpers'

defineProps({
  title: {
    type: String,
    default: 'Присоединяйся к команде',
  },
  socials: {},
  text: {
    type: String,
    default:
      'Подключаем домашний интернет с 2017 года. Выросли из стартапа в компанию с коллективом 60+ человек. Сегодня наши сотрудники работают в разных городах России и центральном офисе в Ростове-на-Дону. Наши партнёры ― десятки федеральных и региональных провайдеров.',
  },
})

const ctx = useNuxtApp()

const imgUrl = (path: string, w?: number, height?: number) => {
  const url = ctx.$thumborURL.setImagePath(path).resize(w, height).buildUrl()
  return url
}
</script>

<style scoped lang="scss">
.c-join {
  margin-bottom: 64px;
  @media (max-width: getBreakpoint(tablet)) {
    margin-bottom: 32px;
  }
  &__title {
    margin-bottom: 12px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 8px;
    }
  }
  &__text {
    margin-bottom: 12px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 8px;
    }
  }
  &__link {
    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 40px;
    }
  }
  &__socials {
    margin-top: 32px;
    @media (max-width: getBreakpoint(tablet)) {
      order: 2;
      margin-top: 24px;
    }
    img {
      width: 48px;
      height: 48px;
    }
    button {
      margin-right: 16px;
    }
  }
  &__img {
    width: 680px;
    object-fit: contain;
    object-position: center;
    float: right;
    margin-left: 20px;
    @media (max-width: getBreakpoint(tablet)) {
      float: unset;
      order: 1;
      margin-left: 0;
      border-radius: 16px;
    }
  }
  &__wrap {
    @media (max-width: getBreakpoint(tablet)) {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
